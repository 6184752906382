/* global FreshworksWidget */
import React, { Suspense, lazy, useEffect } from 'react'

import { ModalSpinner, NotFound } from '@paytheory/pay-theory-ui'

import './App.css'

import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom'

import * as ROUTES from './constants/routes'

const Font = lazy(() =>
  import ('@paytheory/pay-theory-ui/dist/Font'))


const Empty = lazy(() =>
  import ('./views/Empty'))

const Overview = lazy(() =>
  import ('./views/Overview'))

const Web = lazy(() =>
  import ('./views/Web'))

const Android = lazy(() =>
  import ('./views/Android'))

const Ios = lazy(() =>
  import ('./views/Ios'))

const PaymentParameters = lazy(() =>
  import ('./views/PaymentParameters'))

const EmailReceipts = lazy(() =>
    import ('./views/EmailReceipts'))

const API = lazy(() =>
    import ('./views/API'))

// const WooCommerce = lazy(() =>
    //   import ('./views/WooCommerce'))

const App = () => {
  const typekit = process.env.REACT_APP_TYPEKIT

  useEffect(()=>{
    FreshworksWidget('hide', 'launcher');

    return <div />
  },[])

  return (
    <div className="spinner-wrapper">
      <div className="modal-wrapper">
        <Router>
          <Suspense fallback={<ModalSpinner on />}>
            <Routes>
                <Route path="/" element={<Navigate replace to={ROUTES.OVERVIEW} />} />
                <Route
                  exact
                  path={ROUTES.OVERVIEW}
                  element={<Overview />}
                />
                <Route
                  exact
                  path={ROUTES.WEB}
                  element={<Web />}
                />
                <Route
                  exact
                  path={ROUTES.PAYMENT_PARAMETERS}
                  element={<PaymentParameters />}
                />
                <Route
                    exact
                    path={ROUTES.EMAIL_RECEIPTS}
                    element={<EmailReceipts />}
                />
                <Route
                    exact
                    path={ROUTES.API}
                    element={<API />}
                />
                <Route
                    exact
                    path={ROUTES.API_KEY}
                    element={<API />}
                />
                {/*<Route*/}
                {/*  exact*/}
                {/*  path={ROUTES.ANDROID}*/}
                {/*  component={Android}*/}
                {/*/>*/}
                {/*<Route*/}
                {/*  exact*/}
                {/*  path={ROUTES.IOS}*/}
                {/*  component={Ios}*/}
                {/*/>*/}
                <Route path={ROUTES.NOT_FOUND} exact element={<NotFound />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
            <Font typekit={typekit} />
          </Suspense>
        </Router>
        <ModalSpinner />
      </div>
    </div>
  )
}

export default App
