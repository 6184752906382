//authentication
export const OVERVIEW = '/overview'
export const WEB = '/web'
export const IOS = '/ios'
export const ANDROID = '/android'
export const WOOCOMMERCE = '/woocommerce'
export const PAYMENT_PARAMETERS = '/payment-parameters'
export const EMAIL_RECEIPTS = '/email-receipts'
export const API_KEY = '/api/:key'
export const API = '/api'

//error
export const VERIFICATION_FAILED = '/verification-failed'
export const NOT_FOUND = '/not-found'
export const UNAUTHORIZED = '/unauthorized'

